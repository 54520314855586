import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import uuid from 'uuid'

import BgImage from '../BgImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'


import './style.sass'

const RelatedRooms = ({active_id}) => (
  <StaticQuery
    query={graphql`
      query RelatedRooms {
        allMarkdownRemark (
          limit: 10
          filter: {
            frontmatter: {type: {in: ["Luxury room", "Luxury suite"]}}
          }
          sort: { order: DESC, fields: [frontmatter___date]}
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                type
                path
                price
                thumb {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 245, maxHeight: 165) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}

    render={data => (
      <div className="related-rooms-wrapper">
        <div className="related-rooms-title">Similar rooms</div>
        <ul className="related-rooms">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <li
              className={"related-room"+( node.id === active_id ? ' is-active': '')}
              key={uuid.v4()}
            >
              <Link
                to={node.frontmatter.path}
                onClick={() => {
                  document.body.classList.remove('show-sidebar')
                }}
              >
                <div className="related-room-pic-wrapper">
                  <span className="chevron-left">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </span>  
                  <BgImage image={node.frontmatter.thumb} className="related-room-pic"/>
                </div>
                <div className="related-room-title">{node.frontmatter.title}</div>
                <div className="related-room-price">{node.frontmatter.price} / Night</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  
  />
)

export default RelatedRooms













