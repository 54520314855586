import React from 'react'

const ScrollToBtn = (props) => (
  <button
    className={props.className}
    onClick={() => {
      let dist_elm = document.querySelector(props.destination)
      let overflowed_elm = document.querySelector(props.overflowedDiv)
      
      if(window.innerWidth <= 767)
        overflowed_elm = document.getElementsByTagName('body')[0]

      overflowed_elm.scrollTop = dist_elm.offsetTop

    }}
  >{props.title}</button>
)

export default ScrollToBtn